<div
  class="loader-mask"
  *ngIf="isLoading || isLoadingStatus"
>
  <adxad-loader [diameter]="40"></adxad-loader>
</div>

<adxad-sidebar-modal-container *ngIf="publisher && !isLoading">
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="closeModal()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>
      <span
        class="material-icons pointer"
        *ngIf="breadcrumbs.list.length"
        (click)="goBack()"
        >arrow_back_ios</span
      >
      {{ publisher.email }}
    </h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section adxadSidebarModalSection>
      <div class="row">
        <adxad-sidebar-modal-info-block
          class="publisher-view__balance"
          label="{{ 'label_balance' | transloco }}"
          [class]="publisher.blockedBalance > 0 ? 'col-6' : 'col-12'"
        >
          {{ publisher.balance | currency }}

          <button
            adxadStrokedButton
            class="publisher-view__balance-btn"
            [disabled]="publisher.balance === 0"
            (click)="orderPayout()"
          >
            {{ 'action_orderPayout' | transloco }}
          </button>
        </adxad-sidebar-modal-info-block>
        <adxad-sidebar-modal-info-block
          class="publisher-view__balance col-6"
          label="{{ 'blockedBalance' | transloco }}"
          *ngIf="publisher.blockedBalance > 0"
        >
          {{ publisher.blockedBalance | currency }}
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section adxadSidebarModalSection>
      <div class="row">
        <adxad-sidebar-modal-info-block
          class="col-12"
          label="Status"
        >
          <adxad-entity-status [entityStatus]="publisher.status"></adxad-entity-status>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="Created at"
        >
          {{ publisher.createdAt | date: 'MM/dd/y H:mm:ss' }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="Updated at"
        >
          {{ publisher.updatedAt | date: 'MM/dd/y H:mm:ss' }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="First name"
          *ngIf="publisher.firstName"
        >
          {{ publisher.firstName }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="Last name"
          *ngIf="publisher.lastName"
        >
          {{ publisher.lastName }}
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      *ngIf="roles.isAdmin()"
    >
      <h4
        class="publisher-view__section-title"
        (click)="isOpenSection.accountManager = !isOpenSection.accountManager"
      >
        Account manager

        <span
          class="material-icons publisher-view__section-title-icon"
          [class.open]="isOpenSection.accountManager"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.accountManager"
        @slideToggle
      >
        <ng-container
          [ngTemplateOutlet]="
            publisher.manager.avatar ? isAvatar : managerBio
          "
        ></ng-container>

        <ng-template #isAvatar>
          <div class="col-3">
            <img
              class="avatar"
              src="{{ publisher.manager.avatar }}"
              alt="{{ publisher.manager.firstName }} {{
                publisher.manager.lastName
              }}"
            />
          </div>
          <div class="col-9">
            <div class="row">
              <ng-container [ngTemplateOutlet]="managerBio"></ng-container>
            </div>
          </div>
        </ng-template>

        <ng-template #managerBio>
          <adxad-sidebar-modal-info-block
            class="col-6"
            label="First name"
            *ngIf="publisher.manager.firstName"
          >
            {{ publisher.manager.firstName }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="Last name"
            *ngIf="publisher.manager.lastName"
          >
            {{ publisher.manager.lastName }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="E-mail"
            *ngIf="publisher.manager.email"
          >
            {{ publisher.manager.email }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="col-6"
            label="Contact phone"
            *ngIf="publisher.manager.phoneNumber && roles.isAdmin()"
          >
            <a
              class="phone-number"
              href="tel:{{ publisher.manager.phoneNumber }}"
            >
              {{ publisher.manager.phoneNumber }}
            </a>
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            class="languages-list"
            [class]="publisher.manager.avatar ? 'col-12' : 'col-6'"
            label="Languages"
            *ngIf="publisher.manager.languages.length"
          >
            <span
              class="languages-list__item"
              *ngFor="let lang of publisher.manager.languages"
            >
              {{ lang }}
            </span>
          </adxad-sidebar-modal-info-block>
        </ng-template>

        <adxad-sidebar-modal-info-block
          label="Messengers"
          class="col-12"
          *ngIf="publisher.manager.messengers.length"
        >
          <adxad-messengers-list [list]="publisher.manager.messengers"></adxad-messengers-list>
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      *ngIf="publisher.profile.tax.type"
    >
      <h4
        class="publisher-view__section-title"
        (click)="isOpenSection.taxStatus = !isOpenSection.taxStatus"
      >
        Tax status

        <span
          class="material-icons publisher-view__section-title-icon"
          [class.open]="isOpenSection.taxStatus"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.taxStatus"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          class="col-6"
          label="Tax status"
        >
          {{ publisher.profile.tax.type === 1 ? 'Individual' : 'Company' }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="VAT / Tax ID"
        >
          {{ publisher.profile.tax.vat }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-12"
          label="Company name"
          *ngIf="publisher.profile.tax.type === 2"
        >
          {{ publisher.profile.tax.companyName }}
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      [hideSeparator]="false"
      *ngIf="!isEmptyContacts()"
    >
      <h4
        class="publisher-view__section-title"
        (click)="isOpenSection.contacts = !isOpenSection.contacts"
      >
        Contacts

        <span
          class="material-icons publisher-view__section-title-icon"
          [class.open]="isOpenSection.contacts"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.contacts"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          label="Phone number"
          class="col-12"
          *ngIf="publisher.profile.phoneNumber"
        >
          {{ publisher.profile.phoneNumber }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="Messengers"
          class="col-12"
          *ngIf="publisher.profile.messengers.length"
        >
          <adxad-messengers-list [list]="publisher.profile.messengers"></adxad-messengers-list>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="languages-list col-6"
          label="Languages"
          *ngIf="publisher.profile.languages.length"
        >
          <span
            class="languages-list__item"
            *ngFor="let lang of publisher.profile.languages"
          >
            {{ lang }}
          </span>
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      *ngIf="!isEmptyAddress()"
    >
      <h4
        class="publisher-view__section-title"
        (click)="isOpenSection.address = !isOpenSection.address"
      >
        Physical address

        <span
          class="material-icons publisher-view__section-title-icon"
          [class.open]="isOpenSection.address"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.address"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          label="Country"
          class="col-6"
          *ngIf="publisher.profile.address.country"
        >
          {{ publisher.profile.address.country }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="City"
          class="col-6"
          *ngIf="publisher.profile.address.city"
        >
          {{ publisher.profile.address.city }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="ZIP"
          class="col-6"
          *ngIf="publisher.profile.address.zip"
        >
          {{ publisher.profile.address.zip }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="Address"
          class="col-6"
          *ngIf="publisher.profile.address.address"
        >
          {{ publisher.profile.address.address }}
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      [hideSeparator]="publisher.trafficFilter.dsps?.value.length <= 0"
      *ngIf="publisher.payment.type || publisher.payment.method"
    >
      <h4
        class="publisher-view__section-title"
        (click)="isOpenSection.payment = !isOpenSection.payment"
      >
        Payment method information

        <span
          class="material-icons publisher-view__section-title-icon"
          [class.open]="isOpenSection.payment"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.payment"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          label="Payment method"
          class="col-6"
          *ngIf="publisher.payment.method"
        >
          {{ getPaymentMethod(publisher.payment.method) }}
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="Wallet"
          class="col-6"
          *ngIf="publisher.payment.method !== 'transfer'"
        >
          {{ publisher.payment.wallet }}
        </adxad-sidebar-modal-info-block>

        <ng-container *ngIf="publisher.payment.method === 'transfer'">
          <adxad-sidebar-modal-info-block
            label="Payment type"
            class="col-6"
            *ngIf="publisher.payment.type"
          >
            {{ publisher.payment.type | titlecase }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            label="Beneficiary name"
            class="col-6"
            *ngIf="publisher.payment.beneficiaryName"
          >
            {{ publisher.payment.beneficiaryName }}
          </adxad-sidebar-modal-info-block>
          <adxad-sidebar-modal-info-block
            label="Beneficiary address"
            class="col-6"
            *ngIf="publisher.payment.bankAddress"
          >
            {{ publisher.payment.beneficiaryAddress }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            label="SWIFT"
            class="col-6"
            *ngIf="publisher.payment.SWIFT"
          >
            {{ publisher.payment.SWIFT }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            label="IBAN"
            class="col-6"
            *ngIf="publisher.payment.IBAN"
          >
            {{ publisher.payment.IBAN }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            label="Company name"
            class="col-6"
            *ngIf="publisher.payment.companyName"
          >
            {{ publisher.payment.companyName }}
          </adxad-sidebar-modal-info-block>

          <adxad-sidebar-modal-info-block
            label="Phone number"
            class="col-6"
            *ngIf="publisher.payment.phoneNumber"
          >
            {{ publisher.payment.phoneNumber }}
          </adxad-sidebar-modal-info-block>
        </ng-container>
      </div>
    </section>

    <!--   Traffic filters   -->
    <section
      adxadSidebarModalSection
      [hideSeparator]="true"
      *ngIf="publisher.trafficFilter.dsps?.value.length"
    >
      <h4
        class="publisher-view__section-title"
        (click)="isOpenSection.dsps = !isOpenSection.dsps"
      >
        {{ publisher.trafficFilter.dsps.value.length }} {{ publisher.trafficFilter.dsps.value.length > 1 ? 'DSP\'s' : 'DSP' }} {{
        publisher.trafficFilter.dsps.mode ? 'include' : 'exclude' }}

        <span
          class="material-icons section-title__icon"
          [class.open]="isOpenSection.dsps"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <ul
        class="traffic-filter__list"
        *ngIf="isOpenSection.dsps"
        @slideToggle
      >
        <li
          *ngFor="let dsp of publisher.trafficFilter.dsps.value"
          class="traffic-filter__list-item"
        >
          {{ dsp }}
        </li>
      </ul>
    </section>
    <!--   /Traffic filters   -->

    <adxad-sidebar-modal-bar>
      {{ this.publisher.spots.length }} Spots

      <adxad-sidebar-modal-launch-btn
        *ngIf="this.publisher.spots.length && roles.isAdmin()"
        (click)="openSpotsGrid()"
      ></adxad-sidebar-modal-launch-btn>

      <button
        adxadSidebarModalBarAction
        adxadButton
        class="red"
        icon="add"
        (click)="openSpotForm()"
      >
        {{ 'Create spot' | uppercase }}
      </button>
    </adxad-sidebar-modal-bar>

    <adxad-sidebar-modal-search
      (filter)="filter($event)"
      *ngIf="this.publisher.spots.length"
    />

    <adxad-sidebar-modal-list>
      <div
        class="publisher-view__spot"
        *ngFor="let spot of filtered"
        (click)="openSpotView(spot.id)"
      >
        <adxad-entity-status
          [entityStatus]="spot.status"
          [shortView]="true"
        ></adxad-entity-status>
        {{ spot.name }}
      </div>
    </adxad-sidebar-modal-list>

    <div
      class="no-results"
      *ngIf="this.publisher.spots.length === 0 && !isLoading"
    >
      <img
        class="no-results__icon"
        src="/assets/images/svg/not-found-icon.svg"
        alt="No spots found"
      />
      <div class="no-results__text">No spots found</div>
    </div>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <button
      adxadSidebarModalAction
      actionType="main-gray"
      (click)="openStatisticsGrid()"
    >
      <span class="material-icons">query_stats</span>
      Statistics
    </button>

    <button
      adxadSidebarModalAction
      actionType="main-blue"
      (click)="openEditForm()"
    >
      <span class="material-icons">edit</span>
      Edit
    </button>

    <button
      adxadSidebarModalAction
      actionType="main-red"
      (click)="changeStatus(publisherStatus.blocked)"
      *ngIf="publisher.status === publisherStatus.active"
    >
      <span class="material-icons">stop</span>
      Block
    </button>

    <button
      adxadSidebarModalAction
      actionType="main-green"
      (click)="changeStatus(publisherStatus.active)"
      *ngIf="
        publisher.status === publisherStatus.blocked ||
        publisher.status === publisherStatus.pending
      "
    >
      <span class="material-icons">play_arrow</span>
      Activate
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
